<template>
  <form>
    <v-row class="ma-0">
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  label="الاسم"
                  required
                  outlined
                  :counter="20"
                  :error-messages="nameErrors"
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                />
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="isDefault"
                  color="primary"
                  label="تلقائي"
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="isPublic"
                  color="primary"
                  label="عام"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';

import DefaultDialogCRUDDetails from '@/mixins/DefaultDialogCRUDDetails';

const initData = () => ({
  name: '',
  isDefault: true,
  isPublic: true,
});

export default {
  data: () => ({ ...initData() }),
  mixins: [validationMixin, DefaultDialogCRUDDetails()],
  validations() {
    return {
      name: { required, maxLength: maxLength(20) },
    };
  },
  computed: {
    crudOptions() {
      return {
        getUrl: 'identity/roles/' + this.itemId,
        postUrl: 'identity/roles',
        putUrl: 'identity/roles/' + this.itemId,
      };
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength && errors.push('لايمكن تجاوز الحد المسموح');
      !this.$v.name.required && errors.push('الاسم مطلوب');
      return errors;
    },
  },
  methods: {
    isInvalid() {
      this.$v.$touch();
      const isInvalidValidators = this.$v.$invalid;
      if (isInvalidValidators) return true;
      return false;
    },
    postedData() {
      const name = this.name;
      const isDefault = this.isDefault;
      const isPublic = this.isPublic;

      const postData = {
        name,
        isDefault,
        isPublic,
      };
      return postData;
    },
    clearInput() {
      this.clearData(initData());

      // reset validation
      this.$v.$reset();
    },
  },
};
</script>

<style lang="scss" scoped></style>
