<template>
  <DefaultDialog
    width="50%"
    height="40%"
    buttonText="اضافة"
    title="اضافة دور"
    :openToEdit="openToEdit"
    editTitle="تعديل دور"
    :openToView="openToView"
    viewTitle="عرض البيانات"
    @onCloseDialog="onCloseDialog"
  >
    <RolesManagementDetail
      slot-scope="{
        submitWithClose,
        submitWithNew,
        submitWithoutClose,
        closeDialog,
        isEdit,
        isView,
        isNew,
      }"
      :submitWithClose="submitWithClose"
      :submitWithNew="submitWithNew"
      :submitWithoutClose="submitWithoutClose"
      :setItemID="setItemID"
      :closeDialog="closeDialog"
      :isEdit="isEdit"
      :isView="isView"
      :isNew="isNew"
      :afterSubmit="afterSubmit"
      :itemId="itemId"
    />
  </DefaultDialog>
</template>

<script>
import CRUDMixin from '@/mixins/DefaultDialogCRUD';
import RolesManagementDetail from '../curd/RolesManagementDetail.vue';
export default {
  components: { RolesManagementDetail },
  mixins: [CRUDMixin()],
};
</script>

<style lang="scss" scoped></style>
