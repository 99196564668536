var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DefaultDialog',{attrs:{"width":"50%","height":"40%","buttonText":"اضافة","title":"اضافة دور","openToEdit":_vm.openToEdit,"editTitle":"تعديل دور","openToView":_vm.openToView,"viewTitle":"عرض البيانات"},on:{"onCloseDialog":_vm.onCloseDialog},scopedSlots:_vm._u([{key:"default",fn:function({
      submitWithClose,
      submitWithNew,
      submitWithoutClose,
      closeDialog,
      isEdit,
      isView,
      isNew,
    }){return _c('RolesManagementDetail',{attrs:{"submitWithClose":submitWithClose,"submitWithNew":submitWithNew,"submitWithoutClose":submitWithoutClose,"setItemID":_vm.setItemID,"closeDialog":closeDialog,"isEdit":isEdit,"isView":isView,"isNew":isNew,"afterSubmit":_vm.afterSubmit,"itemId":_vm.itemId}})}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }