<template>
  <v-card
    :color="config.color"
    class="d-flex flex-column ma-1"
    :style="config.style"
    elevation="1"
    dark
  >
    <v-card-title class="text-h5" v-text="subtitle" />
    <v-spacer />
    <v-card-actions>
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    subtitle() {
      return this.item?.name || '';
    },
  },
  data: () => ({
    config: {
      color: 'primary',
      style: {
        minHeight: '140px',
      },
    },
  }),
};
</script>

<style scoped lang="scss"></style>
