<template>
  <v-card elevation="2" color="background" class="d-flex flex-column flex-1">
    <v-card-title>
      الادوار

      <v-spacer></v-spacer>

      <RoleAddDialog
        v-model="roleAddDialog"
        :afterSubmit="getRolesManagements"
      />

      <RolePermissionAddDialog
        v-model="rolePermissionAddDialog"
        :afterSubmit="() => {}"
      />
    </v-card-title>

    <v-card-text class="d-flex flex-column flex-grow-1">
      <!-- Filters -->
      <v-row class="flex-grow-0">
        <v-col cols="12" class="d-flex align-center">
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="filters.filter"
            flat
            hide-details="auto"
            label="بحث"
            outlined
            prepend-inner-icon="mdi-magnify"
            solo
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Grid -->
      <v-row v-if="$store.state.loading.show">
        <v-flex xs6 md3 lg3 xl2>
          <v-skeleton-loader type="article" />
        </v-flex>
      </v-row>

      <v-row class="flex-grow-0" dense v-else>
        <v-flex xs6 md3 lg3 xl2 v-for="(item, index) in roles" :key="index">
          <RoleCard :item="item">
            <template #actions>
              <template v-if="isCanRead">
                <v-btn
                  small
                  color="#78b200"
                  @click="roleAddDialog.viewRow(item)"
                  class="mr-1"
                >
                  <v-icon color="#fff">mdi-eye</v-icon>
                </v-btn>
              </template>

              <template v-if="isCanUpdate">
                <v-btn
                  small
                  color="#ffa000"
                  @click="roleAddDialog.editRow(item)"
                  class="mr-1"
                >
                  <v-icon color="#fff">mdi-pencil-outline</v-icon>
                </v-btn>
              </template>

              <template v-if="isCanManagePermissions">
                <v-btn
                  small
                  color="primary lighten-2"
                  @click="
                    rolePermissionAddDialog.editRow({
                      name: 'R',
                      key: item.name,
                    })
                  "
                  class="mr-1"
                >
                  <v-icon color="#fff">mdi-check-all</v-icon>
                </v-btn>
              </template>

              <template v-if="isCanDelete">
                <v-btn
                  small
                  color="pink"
                  @click="deleteRole(item)"
                  class="mr-1"
                >
                  <v-icon color="#fff">mdi-delete</v-icon>
                </v-btn>
              </template>
            </template>
          </RoleCard>
        </v-flex>
      </v-row>

      <v-spacer />
      <!-- pagination -->
      <v-row class="justify-center flex-grow-0 my-1">
        <v-col cols="12" class="d-flex justify-space-between align-center">
          <v-pagination v-model="options.pageIndex" :length="numberOfPages" />
          <v-select
            v-model="options.pageSize"
            :items="[5, 10, 15, 20]"
            label="عدد الصفوف"
            solo
            dense
            item-color="white"
            class="flex-grow-0 d-flex align-center"
            hide-details
            style="width: 100px"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import qs from 'qs';
import { showSnackbarFailMessage } from '@/utls/snackbar';
import RoleAddDialog from './dialog/RoleAddDialog.vue';
import RolePermissionAddDialog from './dialog/RolePermissionAddDialog.vue';
import RoleCard from './components/RoleCard.vue';
import { mapActions } from 'vuex';

import PermissionMixin from '@/mixins/PermissionMixin';
import { appPermissions } from '@/libs/auth/permissions/appPermissions';

const initFilters = {
  filter: '',
};
const initOptions = {
  pageIndex: 1,
  pageSize: 15,
  ...initFilters,
};

export default {
  components: {
    RoleAddDialog,
    RolePermissionAddDialog,
    RoleCard,
  },
  data() {
    return {
      roleAddDialog: {},
      rolePermissionAddDialog: {},
      roles: [],
      total: 1,
      options: { ...initOptions },
      filters: { ...initFilters },
      searchTimer: null,
    };
  },
  mixins: [PermissionMixin(appPermissions.roles)],
  computed: {
    skipPerPage() {
      return this.options.pageSize * (this.options.pageIndex - 1);
    },
    numberOfPages() {
      const pages = Math.ceil(this.total / this.options.pageSize) || 1;
      return pages;
    },
  },
  created() {
    this.getRolesManagements();
  },
  methods: {
    ...mapActions('confirm', ['openConfirm']),
    deleteRole(item) {
      this.openConfirm({
        message: `هل انت متأكد من حذف الدور ؟`,
        confirm: () => {
          return this.$http
            .delete('identity/roles/' + item.id, {})
            .then(() => {
              this.getRolesManagements();
              return true;
            })
            .catch((error) => {
              showSnackbarFailMessage('حدث خطأ اثناء عملية الحذف');
              return false;
            });
        },
      });
    },
    getRolesManagements() {
      this.$store.commit('loading/SET_SHOW', true);

      const requestConfig = {
        params: {
          filter: this.options.filter,
          skipCount: this.skipPerPage,
          maxResultCount: this.options.pageSize,
        },
        paramsSerializer: function (params) {
          const queryString = qs.stringify(params, { arrayFormat: 'brackets' });
          return queryString;
        },
      };

      this.$http
        .get('identity/roles', requestConfig)
        .then((response) => {
          const { items, totalCount } = response.data;
          this.roles = items;
          this.total = totalCount;
        })
        .catch((error) => {
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب البيانات');
        })
        .finally(() => this.$store.commit('loading/SET_SHOW', false));
    },
    searchRolesManagement() {},
  },
  watch: {
    options: {
      handler() {
        this.getRolesManagements();
      },
      deep: true,
    },
    'filters.filter': {
      handler() {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(
          () => Object.assign(this.options, this.filters),
          500,
        );
      },
    },
  },
};
</script>
